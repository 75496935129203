import React from "react";
import Container from "../components/Layouts/Container";
import HomeBanner from "../components/Home/HomeBanner";
import HowItWorkSection from "../components/Home/HowItWorkSection";
import WhatWeDoSection from "../components/Home/WhatWeDoSection";
import NewsLetter from "../components/Common/NewsLetter";

const HomePage = () => {
  return (
    <>
      <Container>
        <HomeBanner />
        <HowItWorkSection />
        <WhatWeDoSection />
        <NewsLetter />
      </Container>
    </>
  );
};

export default HomePage;
