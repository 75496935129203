import React from "react";
import Survey from "../components/PublicSurvey/Survey";
import { useLocation, useNavigate } from "react-router-dom";
import { getCheckInvitationTypeList } from "../store/Action/SurveyAction";
import { useDispatch, useSelector } from "react-redux";

const SurveyPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLoggedIn = localStorage.getItem("vUniqueCode");
  const { state } = useLocation();

  const location = useLocation();
  const path = location.pathname;

  const [firstCodeValue, setFirstCodeValue] = React.useState("");
  const [secondCodeValue, setSecondCodeValue] = React.useState("");
  const [answerUniqueCode, setAnswerUniqueCode] = React.useState("");

  const { checkInvitationTypeData } = useSelector(
    (state) => state.checkInvitationType
  );

  React.useEffect(() => {
    if (
      checkInvitationTypeData &&
      Object.keys(checkInvitationTypeData).length > 0 &&
      checkInvitationTypeData.code === 200 &&
      checkInvitationTypeData.data
    ) {
      const { eType, vUniqueCode } = checkInvitationTypeData?.data;
      setAnswerUniqueCode(vUniqueCode);
      localStorage.setItem("participantvUniqueCodeNew", vUniqueCode);

      // Navigating based on eType and path
      if (
        eType === "Participant" &&
        path === `/survey/${firstCodeValue}/${secondCodeValue}`
      ) {
        navigate("/project-details", {
          state: {
            path,
            // participantvUniqueCode: localStorage.getItem(
            //   "participantvUniqueCodeNew"
            // ),
          },
        });
      } else if (
        (eType === "Organisation" || eType === "Academic Researcher") &&
        path === `/survey/${firstCodeValue}/${secondCodeValue}` &&
        userLoggedIn
      ) {
        navigate("/project-details", { state: path });
      } else if (
        (eType === "Organisation" || eType === "Academic Researcher") &&
        path === `/survey/${firstCodeValue}/${secondCodeValue}` &&
        !userLoggedIn
      ) {
        navigate("/login", { state: path });
      }
    }
  }, [
    checkInvitationTypeData,
    path,
    firstCodeValue,
    secondCodeValue,
    userLoggedIn,
    navigate,
  ]);

  React.useEffect(() => {
    const parts = path?.split("/survey/")[1];
    const uniqueCodes = parts?.split("/");
    const firstUniqueCode = uniqueCodes ? uniqueCodes[0] : "";
    setFirstCodeValue(firstUniqueCode);
    const secondUniqueCode = uniqueCodes ? uniqueCodes[1] : "";
    setSecondCodeValue(secondUniqueCode);

    if (
      !checkInvitationTypeData ||
      Object.keys(checkInvitationTypeData).length < 1
    ) {
      if(secondUniqueCode){
      dispatch(
        getCheckInvitationTypeList({
          vInviteCode: firstUniqueCode,
        })
      );
    }
    }
  }, [state, path, dispatch, checkInvitationTypeData]);

  return (
    <div>
      <Survey />
    </div>
  );
};

export default SurveyPage;
