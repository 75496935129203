import React, { useState } from "react";
import Header from "../Layouts/Header";
import {useLocation, useNavigate } from "react-router-dom";

const Questiontwo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const vUniqueCode = state?.vUniqueCode || null;

  const participantvUniqueCode = state?.participantvUniqueCode;
  const [loading, setLoading] = React.useState(false);

  const SurveyId = state?.SurveyId || null;

  const [education, setEducation] = useState(
    localStorage.getItem("education") || ""
  );
  const [role, setRole] = useState(localStorage.getItem("role") || "");

  const [researchOption, setResearchOption] = useState(
    localStorage.getItem("researchOption") || ""
  );

  const [otherValue, setOtherValue] = useState(
    localStorage.getItem("Other") || ""
  );

  // Update localStorage when the user changes any select option
  const handleEducationChange = (e) => {
    setEducation(e.target.value);
    localStorage.setItem("education", e.target.value);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
    localStorage.setItem("role", e.target.value);
  };

  const handleResearchOptionChange = (e) => {
    setResearchOption(e.target.value);
    localStorage.setItem("researchOption", e.target.value);
  };

  const handleOtherChange = (e) => {
    setOtherValue(e.target.value);
    localStorage.setItem("Other", e.target.value);
  };

  const handleBackClick = () => {
    navigate("/project-details", {
      state: {
        vUniqueCode: vUniqueCode,
        SurveyId: SurveyId,
        participantvUniqueCode: participantvUniqueCode,
      },
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    setTimeout(() => {
      if (!education && !researchOption && !role) {
        navigate("/survey/thank-you");
      }
      if (
        researchOption === "No" ||
        researchOption === "Not Sure" ||
        researchOption === ""
      ) {
        navigate("/survey/thank-you");
      } else {
        navigate("/survey", {
          state: {
            vUniqueCode: vUniqueCode,
            SurveyId: SurveyId || "",
            participantvUniqueCode: participantvUniqueCode,
          },
        });
      }
      setLoading(false);
    }, 1000);
  };

  return (
    <>
      <Header />
      <div className="questions-wrp survey-page">
        <div className="container">
          <h1 className="main-title">Questions</h1>
          <div className="survey-content">
            <div className="row">
              <div className="col-md-6">
                <div className="select-dropdown">
                  <label>Higher Education institution</label>
                  <select
                    name="Education"
                    id="Education"
                    value={education}
                    onChange={handleEducationChange}
                  >
                    <option value="">
                      Select Higher Education institution
                    </option>
                    <option value="Nottingham">
                      Nottingham Trent University
                    </option>
                    <option value="University of Nottingham">
                      University of Nottingham
                    </option>
                    <option value="University of Law">University of Law</option>
                    <option value="Nottingham College">
                      Nottingham College
                    </option>
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <div className="other-select-wrp">
                  <div className="select-dropdown">
                    <label>Your academic role</label>
                    <select
                      name="role"
                      id="role"
                      value={role}
                      onChange={handleRoleChange}
                    >
                      <option value="">Select Your academic role</option>
                      <option value="Lecturer">Lecturer</option>
                      <option value="Research fellow">Research fellow</option>
                      <option value="Research associate">
                        Research associate
                      </option>
                      <option value="Senior Lecturer">Senior Lecturer</option>
                      <option value="Associate Professor">
                        Associate Professor
                      </option>
                      <option value="Professor">Professor</option>
                      <option value="Senior Research Fellow">
                        Senior Research Fellow
                      </option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  {role === "Other" && (
                    <div className="other-slect">
                      <label style={{ fontWeight: "bold" }}>Other:</label>
                      <input
                        type="text"
                        name="other"
                        value={otherValue}
                        onChange={handleOtherChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <h3>
              Have you engaged in research with community-based organisations in
              the past 5 years?
            </h3>
            <div className="questions-desc">
              <p>
                By community-based organisation, we mean a nonprofit entity
                (typically an organisation within the VCSE sector), created and
                managed by members of a specific community to address the needs
                and challenges of the community.{" "}
              </p>
            </div>
            <div className="yes-no-dropdown">
              <select
                name="option"
                id="option"
                value={researchOption}
                onChange={handleResearchOptionChange}
              >
                <option value="">Select The Option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Not Sure">Not Sure</option>
              </select>
            </div>
            {loading ? (
              <>
                {" "}
                <button className="next-page login-btn" type="button" disabled>
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              </>
            ) : (
              <button
                className="next-page login-btn"
                type="submit"
                onClick={handleSubmit}
              >
                Next
              </button>
            )}
            <button
              className="next-page back-btn"
              type="submit"
              style={{ marginLeft: "20px" }}
              onClick={handleBackClick}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Questiontwo;
