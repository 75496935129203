import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getParticipantEntryList,
  getParticipantSkipEntryList,
  getSurveyPublicInviteList,
  ResetSurveyFormState,
  setUniqueCode,
} from "../../store/Action/SurveyAction";
import { Toast } from "primereact/toast";

const PublicSurveyForm = () => {
  const toast = React.useRef(null);
  const { uniquecode } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [skipLoading, setSkipLoading] = React.useState(false);

  const [nameError, setNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");

  const { surveyPublicInviteData } = useSelector(
    (state) => state.surveyPublicInvite
  );

  const { participantEntryData } = useSelector(
    (state) => state.participantEntry
  );

  const { participantSkipEntryData } = useSelector(
    (state) => state.participantSkipEntry
  );

  const handleNameChange = (event) => {
    const nameValue = event.target.value.trim();
    event.target.value.trim() !== "" && setNameError("");
    setName(event.target.value);
    if (nameValue.length === 0 && nameValue === "") {
      setNameError("Name is required.");
    }
  };

  const handleEmailChange = (event) => {
    const emailValue = event.target.value.trim();
    setEmail(emailValue);

    event.target.value.trim() !== "" && setEmailError("");

    // Regular expsion for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailValue.trim() === "" && emailValue.length === 0) {
      setEmailError("Email is required.");
    } else if (!emailRegex.test(emailValue)) {
      setEmailError("Invalid email format.");
    } else {
      setEmailError("");
    }
  };

  const ValidDetails = React.useCallback(() => {
    let isValid = true;
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    setNameError("");

    setEmailError("");

    if (name.length === 0 && name === "") {
      setNameError("Name is required.");
      isValid = false;
    }

    if (email.length === 0 && email === "") {
      setEmailError("Email is required.");
      isValid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Invalid email format.");
      isValid = false;
    }

    if (!isValid) {
      return false;
    }
    return true;
  }, [name, setNameError, email, setEmailError]);

  React.useEffect(() => {
    dispatch(
      getSurveyPublicInviteList({
        vUniqueCode: uniquecode,
      })
    );
  }, [uniquecode, dispatch]);

  React.useEffect(() => {
    if (
      surveyPublicInviteData &&
      surveyPublicInviteData.code === 200 &&
      surveyPublicInviteData.data
    ) {
      // console.log("hiiiiiiiiiiiiiiiiiiiii");
    } else if (surveyPublicInviteData && surveyPublicInviteData.code === 404) {
      navigate("/error", {
        state: {
          code: surveyPublicInviteData?.code,
        },
      });
    }
  }, [surveyPublicInviteData, navigate]);

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();

      const isValidData = ValidDetails();
      if (isValidData) {
        dispatch(
          getParticipantEntryList({
            vName: name,
            vEmail: email,
          })
        );
        setLoading(true);
      } else {
      }
    },
    [ValidDetails, name, email, dispatch]
  );

  React.useEffect(() => {
    if (participantEntryData && participantEntryData.code === 201) {
      // setLoading(true);
      dispatch(ResetSurveyFormState());
      toast.current.show({
        severity: "success",
        summary: participantEntryData.message,
        life: 2000,
        className: "custom-toast",
      });
      setTimeout(() => {
        setName("");
        setEmail("");
        setLoading(false);
        const participantUniqueCode = participantEntryData?.data?.vUniqueCode;

        dispatch(setUniqueCode(participantUniqueCode));
        navigate("/project-details", { state: { vUniqueCode: uniquecode } });
      }, 2000);
    } else if (participantEntryData && participantEntryData.code === 400) {
      setLoading(false);
      dispatch(ResetSurveyFormState());
      toast.current.show({
        severity: "error",
        summary: participantEntryData.message,
        life: 2000,
        className: "custom-toast-error",
      });
      setTimeout(() => {
        setEmail("");
        setName("");
      }, 2000);
    } else if (participantEntryData && participantEntryData.code === 401) {
      setLoading(false);
      dispatch(ResetSurveyFormState());
      toast.current.show({
        severity: "error",
        summary: participantEntryData.message,
        life: 2000,
        className: "custom-toast-error",
      });
      setTimeout(() => {
        setEmail("");
        setName("");
      }, 2000);
    }
  }, [participantEntryData, dispatch, navigate, uniquecode]);

  const handleSkip = React.useCallback(
    async (e) => {
      e.preventDefault();
      setSkipLoading(true);
      try {
        const response = await fetch("https://ipinfo.io/json");

        if (!response.ok) {
          switch (response.status) {
            case 400:
              toast.current.show({
                severity: "error",
                summary: "Bad request. Please try again later.",
                life: 2000,
                className: "custom-toast-error",
              });
              break;
            case 401:
              toast.current.show({
                severity: "error",
                summary: "Unauthorized access. Please check your credentials.",
                life: 2000,
                className: "custom-toast-error",
              });
              break;
            case 403:
              toast.current.show({
                severity: "error",
                summary:
                  "Access forbidden. You do not have permission to access this resource.",
                life: 2000,
                className: "custom-toast-error",
              });
              break;
            case 404:
              toast.current.show({
                severity: "error",
                summary: "Resource not found. Please try again later.",
                life: 2000,
                className: "custom-toast-error",
              });
              break;
            case 429:
              toast.current.show({
                severity: "error",
                summary: "API limit exceeded. Please try again later.",
                life: 2000,
                className: "custom-toast-error",
              });
              break;
            case 500:
              toast.current.show({
                severity: "error",
                summary: "Server error. Please try again later.",
                life: 2000,
                className: "custom-toast-error",
              });
              break;
            case 502:
              toast.current.show({
                severity: "error",
                summary: "Bad gateway. Please try again later.",
                life: 2000,
                className: "custom-toast-error",
              });
              break;
            case 503:
              toast.current.show({
                severity: "error",
                summary: "Service unavailable. Please try again later.",
                life: 2000,
                className: "custom-toast-error",
              });
              break;
            case 504:
              toast.current.show({
                severity: "error",
                summary: "Gateway timeout. Please try again later.",
                life: 2000,
                className: "custom-toast-error",
              });

              break;
            default:
              toast.current.show({
                severity: "error",
                summary: `Unexpected error: ${response.statusText}`,
                life: 2000,
                className: "custom-toast-error",
              });

              break;
          }
        } else {
          const data = await response.json();

          const [latitude, longitude] = data.loc
            .split(",")
            .map((coord) => parseFloat(coord));

          dispatch(
            getParticipantSkipEntryList({
              vIp: data?.ip,
              vRegion: data?.region,
              vCountryCode: data?.country,
              vCity: data?.city,
              vZip: data?.postal,
              vLatitude: latitude,
              vLongitude: longitude,
              vTimeZone: data?.timezone,
            })
          );
        }
      } catch (err) {
        if (err.name === "TypeError") {
          toast.current.show({
            severity: "error",
            summary:
              "Network error. Please check your connection and try again.",
            life: 2000,
            className: "custom-toast-error",
          });
        } else if (err.message.includes("429")) {
          toast.current.show({
            severity: "error",
            summary: "API limit exceeded. Please try again later.",
            life: 2000,
            className: "custom-toast-error",
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "An unexpected error occurred. Please try again later.",
            life: 2000,
            className: "custom-toast-error",
          });
        }
        // console.error("Error fetching IP API:", err.message);
      }
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (participantSkipEntryData && participantSkipEntryData.code === 201) {
      setSkipLoading(false);
      const participantUniqueCode = participantSkipEntryData?.data?.vUniqueCode;
      dispatch(setUniqueCode(participantUniqueCode));

      navigate("/project-details", { state: { vUniqueCode: uniquecode } });
    } else if (
      participantSkipEntryData &&
      participantSkipEntryData.data &&
      participantSkipEntryData.status === 400
    ) {
      toast.current.show({
        severity: "error",
        summary: "Bad request. Please try again later.",
        life: 2000,
        className: "custom-toast-error",
      });
    }
  }, [participantSkipEntryData, dispatch, navigate, uniquecode]);

  return (
    <React.Fragment>
      <Toast ref={toast} />
      {surveyPublicInviteData && surveyPublicInviteData.code === 200 ? (
        <div className="container">
          <div className="form-container" style={{ marginTop: "100px" }}>
            <div style={{ textAlign: "center" }}>
              <Link to="/" className="login-form-logo">
                <img src="../assets/image/login-logo.png" alt="project logo" />
              </Link>
            </div>
            <h5 style={{ marginTop: "20px", textAlign: "center" }}>
              Survey Invitation
            </h5>
            <p
              style={{
                marginTop: "10px",
                marginBottom: "30px",
                textAlign: "center",
                fontSize: "15px",
              }}
            >
              provide your name & email for better survey experience
            </p>
            <form style={{ marginTop: "20px" }} onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="input-box">
                    <label htmlFor="exampleInputName" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      value={name}
                      onChange={handleNameChange}
                    />
                    {nameError && (
                      <div
                        className="error-msg"
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginLeft: "5px",
                        }}
                      >
                        {nameError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input-box">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Email address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email Address"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    {emailError && (
                      <div
                        className="error-msg"
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginLeft: "5px",
                        }}
                      >
                        {emailError}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="login-btn-view">
                    {loading ? (
                      <>
                        {" "}
                        <button className="login-btn" type="button" disabled>
                          <span
                            className="spinner-border spinner-border-sm me-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Loading...
                        </button>
                      </>
                    ) : (
                      <button type="submit" className="login-btn">
                        Submit
                      </button>
                    )}
                    {skipLoading ? (
                      <>
                        {" "}
                        <button className="login-btn" type="button" disabled>
                          <span
                            className="spinner-border spinner-border-sm me-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Loading...
                        </button>
                      </>
                    ) : (
                      <button
                        type="button"
                        className="login-btn cancel-btn"
                        onClick={handleSkip}
                      >
                        Skip
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default PublicSurveyForm;
