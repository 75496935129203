import React from "react";
import Header from "../Layouts/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  getSurveyAnswerList,
  getSurveyQuestionList,
} from "../../store/Action/SurveyAction";
import { useLocation, useNavigate } from "react-router-dom";

const QuestionThree = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const vUniqueCode = state?.vUniqueCode || null;

  const SurveyId = state?.SurveyId || null;

  const participantvUniqueCodeNew = localStorage.getItem(
    "participantvUniqueCodeNew"
  );

  const [buttonLoading, setButtonLoading] = React.useState(false);

  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(() => {
    const savedIndex = localStorage.getItem("currentQuestionIndex");
    return savedIndex ? parseInt(savedIndex, 10) : 0;
  });

  const [errors, setErrors] = React.useState({});
  const [email, setEmail] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState(1);
  const [emailError, setEmailError] = React.useState("");
  const [selectedCategories, setSelectedCategories] = React.useState(
    JSON.parse(localStorage.getItem("selectedCategories")) || {}
  );

  const { surveyQuestionData, loading } = useSelector(
    (state) => state.surveyQuestion
  );

  const participantSurveyId = surveyQuestionData?.data?.Data?.[0]?.iSurveyId;

  const { surveyAnswerData } = useSelector((state) => state.surveyAnswer);

  const userUniqueCode = localStorage.getItem("vUniqueCode");

  const { participantUniqueCode } = useSelector((state) => state.participant);

  // Save currentQuestionIndex to localStorage when it changes
  React.useEffect(() => {
    localStorage.setItem("currentQuestionIndex", currentQuestionIndex);
  }, [currentQuestionIndex]);

  // Retrieve email from localStorage when the component mounts
  React.useEffect(() => {
    const savedEmail = localStorage.getItem("email");
    if (savedEmail) {
      setEmail(savedEmail);
    }
  }, []);

  const handleEmailChange = (event) => {
    const emailValue = event.target.value.trim();
    setEmail(emailValue);
    event.target.value.trim() !== "" && setEmailError("");

    // Regular expsion for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailValue)) {
      setEmailError("Invalid Email format.");
    } else {
      setEmailError("");
      localStorage.setItem("email", emailValue);
    }
  };

  React.useEffect(() => {
    const cleanedUniqueCode = vUniqueCode && vUniqueCode.replace(/^"|"$/g, "");
    dispatch(
      getSurveyQuestionList({
        vUniqueCode: cleanedUniqueCode,
        // iPageNo:1 ,
      })
    );
  }, [dispatch, vUniqueCode]);

  React.useEffect(() => {
    localStorage.setItem(
      "selectedCategories",
      JSON.stringify(selectedCategories)
    );
  }, [selectedCategories]);

  const handleCategorySelection = (categoryId) => {
    setSelectedCategories((prevSelected) => {
      const updatedCategories = {
        ...prevSelected,
        [categoryId]: {
          ...prevSelected[categoryId],
          selected: !prevSelected[categoryId]?.selected,
          subCategories: prevSelected[categoryId]?.subCategories || {},
          isValid: prevSelected[categoryId]?.isValid || false,
        },
      };

      const isAnyCategorySelected = Object.values(updatedCategories).some(
        (category) => category.selected
      );

      // Update errors for current question index
      setErrors((prevErrors) => ({
        ...prevErrors,
        [currentQuestionIndex]: isAnyCategorySelected
          ? ""
          : "Please select at least one category.",
      }));

      return updatedCategories;
    });
  };

  const handleSubCategorySelection = (categoryId, subCategoryId) => {
    setSelectedCategories((prevSelected) => {
      const updatedSubCategories = {
        ...prevSelected[categoryId],
        subCategories: {
          ...prevSelected[categoryId]?.subCategories,
          [subCategoryId]:
            !prevSelected[categoryId]?.subCategories?.[subCategoryId],
        },
      };

      const isSubCategorySelected = Object.values(
        updatedSubCategories.subCategories
      ).some((isSelected) => isSelected);

      const updatedCategories = {
        ...prevSelected,
        [categoryId]: {
          ...updatedSubCategories,
          isValid: isSubCategorySelected,
        },
      };

      const isAllSelectedCategoriesValid = Object.values(
        updatedCategories
      ).every((category) => !category.selected || category.isValid);

      // Update errors for current question index
      setErrors((prevErrors) => ({
        ...prevErrors,
        [currentQuestionIndex]: isAllSelectedCategoriesValid
          ? ""
          : "Please select at least one subcategory for each selected category.",
      }));

      return updatedCategories;
    });
  };

  // Handle the next question navigation
  const handleNextQuestion = async () => {
    const currentQuestion =
      surveyQuestionData?.data?.Data[currentQuestionIndex];

    const currentQuestionId =
      surveyQuestionData?.data?.Data[currentQuestionIndex].iQuestionId;
    const currentQuestionType =
      surveyQuestionData?.data?.Data[currentQuestionIndex].eType;

    const isAnyCategorySelected = currentQuestion?.categories?.some(
      (category) => selectedCategories[category.iCategoryId]?.selected
    );

    if (!isAnyCategorySelected) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [currentQuestionIndex]: "Please select at least one category.",
      }));
      return;
    }

    const isAllSelectedCategoriesValid = currentQuestion?.categories?.every(
      (category) => {
        if (selectedCategories[category.iCategoryId]?.selected) {
          const hasSelectedSubCategory = Object.values(
            selectedCategories[category.iCategoryId]?.subCategories || {}
          ).some((isSelected) => isSelected);
          return hasSelectedSubCategory;
        }
        return true;
      }
    );

    if (!isAllSelectedCategoriesValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [currentQuestionIndex]:
          "Please select at least one subcategory for each selected category.",
      }));
      return;
    }

    // Clear error for the current question when moving to the next
    setErrors((prevErrors) => ({
      ...prevErrors,
      [currentQuestionIndex]: "",
    }));

    const iOptionId = currentQuestion?.categories
      .map((category) => {
        if (selectedCategories[category.iCategoryId]?.selected) {
          const selectedSubCategoryIds = Object.keys(
            selectedCategories[category.iCategoryId]?.subCategories || {}
          ).filter(
            (subCatId) =>
              selectedCategories[category.iCategoryId].subCategories[subCatId]
          );

          return {
            [category.iCategoryId]: selectedSubCategoryIds.map(Number),
          };
        }
        return null;
      })
      .filter(Boolean);

    const payload = {
      vUniqueCode:
        participantUniqueCode || userUniqueCode || participantvUniqueCodeNew,
      iQuestionId: [{ [currentQuestionId]: currentQuestionType }],
      iOptionId: [iOptionId],
      iSurveyId: participantSurveyId || SurveyId,
      iPageNo: pageNumber,
    };

    try {
      setButtonLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/question/answer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const responseData = await response.json();
      if (responseData && responseData.status === 200) {
        setButtonLoading(false);
        if (currentQuestionIndex < surveyQuestionData?.data?.Data.length - 1) {
          setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
          window.scroll(0, 0);
        } else {
          // Final submission or next step logic here
        }
      }
      setPageNumber(pageNumber + 1);

      if (
        responseData &&
        responseData.data &&
        responseData.data.survey_status === "Completed"
      ) {
        navigate("/survey/thank-you");
        localStorage.removeItem("selectedCategories");
        localStorage.removeItem("education");
        localStorage.removeItem("researchOption");
        localStorage.removeItem("Other");
        localStorage.removeItem("agree");
        localStorage.removeItem("disagree");
        localStorage.removeItem("currentQuestionIndex");
        localStorage.removeItem("participantvUniqueCodeNew");
        localStorage.removeItem("email");
      }
    } catch (err) {
      // Handle error
    }
  };

  // Handle the back question navigation
  const handleBackQuestion = () => {
    if (currentQuestionIndex > 0) {
      // Clear error for the previous question when going back
      setErrors((prevErrors) => ({
        ...prevErrors,
        [currentQuestionIndex - 1]: "",
      }));
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
    setPageNumber(pageNumber - 1);
  };

  // Handle the back navigation to a previous page
  const handleBack = () => {
    navigate("/general-info", {
      state: { vUniqueCode, SurveyId, participantvUniqueCodeNew },
    });
  };

  return (
    <>
      <Header />
      <div className="questions-wrp survey-category survey-page">
        {loading ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "50px",
                marginBottom: "50px",
              }}
            >
              {loading && (
                <img
                  src="/assets/gif/350.gif"
                  className="loading-gif"
                  alt="Loading..."
                />
              )}
            </div>
          </>
        ) : (
          <>
            {surveyQuestionData &&
            surveyQuestionData?.code === 200 &&
            surveyQuestionData?.data?.Data &&
            surveyQuestionData?.data?.Data.length > 0 ? (
              <>
                <div className="container">
                  <div className="main-category">
                    <h2 className="qus-title">
                      {
                        surveyQuestionData?.data?.Data[currentQuestionIndex]
                          .vQuestion
                      }
                    </h2>
                    {surveyQuestionData?.data?.Data[
                      currentQuestionIndex
                    ]?.categories?.map((category) => (
                      <div
                        className="category-list border-0 pt-0"
                        key={category.iCategoryId}
                      >
                        <div className="form-check">
                          <input
                            type="checkbox"
                            id={`specific-${category.iCategoryId}`}
                            className="form-check-input"
                            onChange={() =>
                              handleCategorySelection(category.iCategoryId)
                            }
                            checked={
                              selectedCategories[category.iCategoryId]
                                ?.selected || false
                            }
                          />
                          <label
                            htmlFor={`specific-${category.iCategoryId}`}
                            className="category-title"
                          >
                            {category.vCategory}
                          </label>
                        </div>

                        {selectedCategories[category.iCategoryId]?.selected && (
                          <div className="sub-category-list">
                            <div className="row g-3">
                              {category?.subCategories?.map((subCategory) => (
                                <div
                                  className="col-md-6 col-lg-4"
                                  key={subCategory.iSubCategoryId}
                                >
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      id={`sub-${category.iCategoryId}-${subCategory.iSubCategoryId}`}
                                      className="form-check-input"
                                      onChange={() =>
                                        handleSubCategorySelection(
                                          category.iCategoryId,
                                          subCategory.iSubCategoryId
                                        )
                                      }
                                      checked={
                                        selectedCategories[category.iCategoryId]
                                          ?.subCategories?.[
                                          subCategory.iSubCategoryId
                                        ] || false
                                      }
                                    />
                                    <label
                                      htmlFor={`sub-${category.iCategoryId}-${subCategory.iSubCategoryId}`}
                                    >
                                      {subCategory.vSubCategory}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                    {/* {error && (
                      <div style={{ color: "red", marginBottom: "10px" }}>
                        {error}
                      </div>
                    )} */}
                    {errors[currentQuestionIndex] && (
                      <div
                        style={{ color: "red", marginBottom: "10px" }}
                        className="error-message"
                      >
                        {errors[currentQuestionIndex]}
                      </div>
                    )}
                  </div>

                  {currentQuestionIndex === 1 && (
                    <div>
                      <h3>
                        If you are interested in gaining access to updates on
                        this research, please enter your contact information
                        below. We will contact you concerning publication of the
                        results:
                      </h3>
                      <div className="row">
                        <div className="col-md-6 col-lg-4">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Email Address"
                              value={email}
                              onChange={handleEmailChange}
                            />
                            {emailError && (
                              <div
                                className="error-msg"
                                style={{
                                  color: "red",
                                  marginLeft: "5px",
                                  fontSize: "14px",
                                }}
                              >
                                {" "}
                                {emailError}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {buttonLoading ? (
                    <button
                      className="next-page login-btn"
                      type="button"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {currentQuestionIndex ===
                      surveyQuestionData.data.Data.length - 1
                        ? "Submitting..."
                        : "Loading..."}
                    </button>
                  ) : (
                    <button
                      className="next-page login-btn"
                      onClick={handleNextQuestion}
                    >
                      {currentQuestionIndex <
                      surveyQuestionData.data.Data.length - 1
                        ? "Next"
                        : "Submit"}
                    </button>
                  )}

                  {/* Conditionally show the Back button */}
                  {currentQuestionIndex > 0 &&
                    currentQuestionIndex <
                      surveyQuestionData.data.Data.length - 1 && (
                      <button
                        style={{ marginLeft: "20px" }}
                        className="next-page back-btn"
                        onClick={handleBackQuestion}
                      >
                        Back
                      </button>
                    )}

                  {currentQuestionIndex === 0 &&
                    surveyQuestionData.data.Data.length > 1 && (
                      <button
                        className="next-page back-btn"
                        type="submit"
                        style={{ marginLeft: "20px" }}
                        onClick={handleBack}
                      >
                        Back
                      </button>
                    )}
                </div>
              </>
            ) : (
              <>
                <div
                  className="no-data"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px 0",
                    marginTop: "50px",
                  }}
                >
                  No Data Found
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default QuestionThree;
