import React from "react";
import DashboardMenu from "../Dashboard/DashboardMenu";
import { useDispatch, useSelector } from "react-redux";
import { getInvitationList } from "../../store/Action/SurveyAction";
import {  useNavigate } from "react-router-dom";

const Invitation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const moment = require("moment");

  const { invitationListData, loading } = useSelector(
    (state) => state.invitationList
  );

  const UniqueCode = localStorage.getItem("vUniqueCode");

  const defaultImage = "../assets/image/about-count-3.png";

  React.useEffect(() => {
    dispatch(
      getInvitationList({
        vUniqueCode: UniqueCode,
      })
    );
  }, [dispatch, UniqueCode]);

  React.useEffect(() => {
    if (invitationListData && invitationListData.code === 200) {
      // console.log("data Updated");
    }
  }, [invitationListData]);

  const handelProceed = (code, Id) => {
    navigate(`/project-details`, {
      state: {
        surveyCode: code,
        surveyId: Id,
      },
    });
  };

  return (
    <section className="my-profile">
      <div className="dashboard-main-content">
        <DashboardMenu />

        <div className="profile-right-view-bg dashboard-right-view">
          <div className="row g-3">
            <div className="col-lg-12">
              <div className="survey-result">
                <h3 className="title">Invitation</h3>
                <div className="table-responsive">
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "50px",
                        marginBottom: "50px",
                      }}
                    >
                      {loading && (
                        <img
                          src="/assets/gif/350.gif"
                          className="loading-gif"
                          alt="Loading..."
                        />
                      )}
                    </div>
                  ) : (
                    <>
                      {invitationListData &&
                      invitationListData.code === 200 &&
                      invitationListData.data ? (
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="text-center">No</th>
                              <th className="text-center">Image</th>
                              <th className="text-center">Name</th>
                              <th className="text-center">Date</th>
                              <th className="text-center">Status</th>
                              <th className="text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {invitationListData &&
                              invitationListData.data &&
                              invitationListData.data &&
                              invitationListData.data.Data &&
                              invitationListData.data.Data.inviteSurvey.map(
                                (invitation, invitationIndex) => (
                                  <tr key={invitationIndex}>
                                    <td className="text-center">
                                      {invitationIndex + 1}
                                    </td>
                                    <td className="text-center">
                                      <div className="table-img">
                                        <img
                                          src={
                                            invitation &&
                                            invitation?.survey &&
                                            invitation?.survey?.vWebpImage
                                              ?.split("/")
                                              ?.pop() === "null"
                                              ? defaultImage
                                              : invitation?.survey?.vWebpImage
                                          }
                                          alt={invitation?.survey?.vImageAlt}
                                          width="40"
                                          height="40"
                                        />
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      {invitation?.survey?.vSurvey}
                                    </td>
                                    <td className="text-center">
                                      {" "}
                                      {moment(invitation?.dtAddedDate).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td className="text-center">
                                      {invitation?.eStatus}
                                    </td>
                                    {invitation &&
                                    invitation.eStatus === "Completed" ? (
                                      <>
                                        <td className="text-center">
                                          <div className="table-btn">
                                            <button
                                              className="show-result"
                                              style={{
                                                backgroundColor: "green",
                                                border: "none",
                                              }}
                                            >
                                              Completed
                                            </button>
                                          </div>
                                        </td>
                                      </>
                                    ) : invitation &&
                                      invitation.eStatus === "Pending" ? (
                                      <>
                                        <td className="text-center">
                                          <div className="table-btn">
                                            <button
                                              onClick={() =>
                                                handelProceed(
                                                  invitation &&
                                                    invitation?.survey &&
                                                    invitation?.survey
                                                      ?.vUniqueCode,
                                                  invitation?.iSurveyId
                                                )
                                              }
                                              className="show-result"
                                            >
                                              Proceed
                                            </button>
                                          </div>
                                        </td>
                                      </>
                                    ) : null}
                                  </tr>
                                )
                              )}
                          </tbody>
                        </table>
                      ) : (
                        <div className="col-12">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "10px 0",
                              marginTop: "10px",
                            }}
                          >
                            No Data Found
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Invitation;
