import React from "react";
import Header from "../Layouts/Header";
import { useDispatch, useSelector } from "react-redux";
import { getSurveyQuestionList } from "../../store/Action/SurveyAction";

const QuestionFour = () => {
  const dispatch = useDispatch();
  const [showpurposes, setShowPurposes] = React.useState(false);
  const [showeducation, setShowEducation] = React.useState(false);
  const [showadvancement, setShowAdvancement] = React.useState(false);
  const [showinternational, setShowInternational] = React.useState(false);
  const [showprevention, setShowPrevention] = React.useState(false);
  const [showhousing, setShowHousing] = React.useState(false);
  const [showculture, setShowCulture] = React.useState(false);
  const [showamateur, setShowAmateur] = React.useState(false);

  const [showenvironment, setShowEnvironment] = React.useState(false);

  const [showarmed, setShowArmed] = React.useState(false);

  const [showharmony, setShowHarmony] = React.useState(false);

  const [showrecreation, setShowRecreation] = React.useState(false);
  const [showcharitable, setShowCharitable] = React.useState(false);


  const { surveyQuestionData } = useSelector((state) => state.surveyQuestion);

  // console.log("surveyQuestionData", surveyQuestionData?.data);

  React.useEffect(() => {
    if (!surveyQuestionData) {
      dispatch(
        getSurveyQuestionList({
          vUniqueCode:
            "8666683506aacd900bbd5a74ac4edf68fa8ec206a7257dfe8e51ef497c582755023a2ad965162b072e7a100eb39f57bc",
        })
      );
    }
  }, [surveyQuestionData, dispatch]);


  return (
    <>
      <Header />
      <div className="questions-wrp survey-category survey-page">
        <div className="container">
          <div className="main-category">
            <h2 className="qus-title">
              What was your target focal areas for research with the
              organisations?
            </h2>
            <div className="category-list border-0 pt-0">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="purposes"
                  className="form-check-input"
                  onChange={() => setShowPurposes(!showpurposes)}
                />
                <label for="purposes" className="category-title">
                  General charitable purposes
                </label>
              </div>

              {/* sub category */}
              {showpurposes && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Education"
                          className="form-check-input"
                        />
                        <label for="Education">Education</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Advocacy"
                          className="form-check-input"
                        />
                        <label for="Advocacy">Advocacy</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Information"
                          className="form-check-input"
                        />
                        <label for="Information">Advice/Information</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="equipment"
                          className="form-check-input"
                        />
                        <label for="equipment">Provides equipment</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="goods"
                          className="form-check-input"
                        />
                        <label for="goods">goods</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="EducationTraining"
                  className="form-check-input"
                  onChange={() => setShowEducation(!showeducation)}
                />
                <label for="EducationTraining" className="category-title">
                  General Education/Training
                </label>
              </div>

              {/* sub category */}
              {showeducation && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="school"
                          className="form-check-input"
                        />
                        <label for="school">Pre-school</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Primary"
                          className="form-check-input"
                        />
                        <label for="Primary">Primary</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Secondary"
                          className="form-check-input"
                        />
                        <label for="Secondary">Secondary</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Further"
                          className="form-check-input"
                        />
                        <label for="Further">Further education</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Higher"
                          className="form-check-input"
                        />
                        <label for="Higher">Higher education</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="vocational"
                          className="form-check-input"
                        />
                        <label for="vocational">
                          Technical vocational training
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Employability"
                          className="form-check-input"
                        />
                        <label for="Employability">Employability</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="training"
                          className="form-check-input"
                        />
                        <label for="training">Other education/training</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Advancement"
                  className="form-check-input"
                  onChange={() => setShowAdvancement(!showadvancement)}
                />
                <label for="Advancement" className="category-title">
                  Advancement of health
                </label>
              </div>

              {/* sub category */}
              {showadvancement && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Cancer"
                          className="form-check-input"
                        />
                        <label for="Cancer">Cancer</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="disease"
                          className="form-check-input"
                        />
                        <label for="disease">Heart disease</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Diabetes"
                          className="form-check-input"
                        />
                        <label for="Diabetes">Diabetes</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Dementia"
                          className="form-check-input"
                        />
                        <label for="Dementia">Dementia</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Mental"
                          className="form-check-input"
                        />
                        <label for="Mental">Mental Health</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Addiction"
                          className="form-check-input"
                        />
                        <label for="Addiction">
                          Addiction (Including substance abuse)
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Organ"
                          className="form-check-input"
                        />
                        <label for="Organ">Organ and tissue donation</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="wellbeing"
                          className="form-check-input"
                        />
                        <label for="wellbeing">
                          Promotion of health and wellbeing
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="prevention"
                          className="form-check-input"
                        />
                        <label for="prevention">
                          Public health and other sickness prevention
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="sickness"
                          className="form-check-input"
                        />
                        <label for="sickness">
                          Relief of sickness generally
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Prevention"
                  className="form-check-input"
                  onChange={() => setShowPrevention(!showprevention)}
                />
                <label for="Prevention" className="category-title">
                  Prevention or relief of poverty
                </label>
              </div>

              {/* sub category */}
              {showprevention && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Unemployment"
                          className="form-check-input"
                        />
                        <label for="Unemployment">Unemployment support</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Food"
                          className="form-check-input"
                        />
                        <label for="Food">Food back</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Homelessness"
                          className="form-check-input"
                        />
                        <label for="Homelessness">Homelessness support</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Campaigning"
                          className="form-check-input"
                        />
                        <label for="Campaigning">Campaigning</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Refugee"
                          className="form-check-input"
                        />
                        <label for="Refugee">Refugee support</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="education"
                          className="form-check-input"
                        />
                        <label for="education">Education</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Other1"
                          className="form-check-input"
                        />
                        <label for="Other1">Other</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="International"
                  className="form-check-input"
                  onChange={() => setShowInternational(!showinternational)}
                />
                <label for="International" className="category-title">
                  International development/Humanitarian aid
                </label>
              </div>

              {/* sub category */}
              {showinternational && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="donation"
                          className="form-check-input"
                        />
                        <label for="donation">Food donation</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="aid"
                          className="form-check-input"
                        />
                        <label for="aid">Medical aid</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Capacity"
                          className="form-check-input"
                        />
                        <label for="Capacity">Capacity Building</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Provides"
                          className="form-check-input"
                        />
                        <label for="Provides">Provides equipment/goods,</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="sanitation"
                          className="form-check-input"
                        />
                        <label for="sanitation">Water/sanitation</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="programmes"
                          className="form-check-input"
                        />
                        <label for="programmes">
                          Exchange/Cultural programmes
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Other2"
                          className="form-check-input"
                        />
                        <label for="Other2">Other</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Housing"
                  className="form-check-input"
                  onChange={() => setShowHousing(!showhousing)}
                />
                <label for="Housing" className="category-title">
                  Housing
                </label>
              </div>

              {/* sub category */}
              {showhousing && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Association"
                          className="form-check-input"
                        />
                        <label for="Association">Housing Association</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="accommodation"
                          className="form-check-input"
                        />
                        <label for="accommodation">
                          Temporary accommodation
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Other3"
                          className="form-check-input"
                        />
                        <label for="Other3">Other</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Culture"
                  className="form-check-input"
                  onChange={() => setShowCulture(!showculture)}
                />
                <label for="Culture" className="category-title">
                  Arts/Culture/Heritage/Science
                </label>
              </div>

              {/* sub category */}
              {showculture && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Preservation"
                          className="form-check-input"
                        />
                        <label for="Preservation">
                          Preservation of historical or cultural traditions
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="historical"
                          className="form-check-input"
                        />
                        <label for="historical">
                          Preservation of historical buildings or ancient sites
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="monuments"
                          className="form-check-input"
                        />
                        <label for="monuments">
                          Preservation of monuments, Preservation of historical
                          or cultural artefacts
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Festivals"
                          className="form-check-input"
                        />
                        <label for="Festivals">Festivals</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Museum"
                          className="form-check-input"
                        />
                        <label for="Museum">Museum of gallery</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="other"
                          className="form-check-input"
                        />
                        <label for="other">other</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Amateur"
                  className="form-check-input"
                  onChange={() => setShowAmateur(!showamateur)}
                />
                <label for="Amateur" className="category-title">
                  Amateur sport
                </label>
              </div>

              {/* sub category */}
              {showamateur && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="sport-governing-body"
                          className="form-check-input"
                        />
                        <label for="sport-governing-body">Sport governing body</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="venue"
                          className="form-check-input"
                        />
                        <label for="venue">Sorts venue</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Sports"
                          className="form-check-input"
                        />
                        <label for="Sports">Sports Clum/Association</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Other4"
                          className="form-check-input"
                        />
                        <label for="Other4">Other</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Environment"
                  className="form-check-input"
                  onChange={() => setShowEnvironment(!showenvironment)}
                />
                <label for="Environment" className="category-title">
                  Environment/Conservation
                </label>
              </div>

              {/* sub category */}
              {showenvironment && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Conservation"
                          className="form-check-input"
                        />
                        <label for="Conservation">
                          Conservation of environment
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="geographic"
                          className="form-check-input"
                        />
                        <label for="geographic">
                          Conservation of particular geographic area
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="species"
                          className="form-check-input"
                        />
                        <label for="species">
                          Conservation of particular species
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="resources"
                          className="form-check-input"
                        />
                        <label for="resources">
                          Conserving natural resources
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Other5"
                          className="form-check-input"
                        />
                        <label for="Other5">Other</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Armed"
                  className="form-check-input"
                  onChange={() => setShowArmed(!showarmed)}
                />
                <label for="Armed" className="category-title">
                  Armed Forces/Emergency services
                </label>
              </div>

              {/* sub category */}
              {showarmed && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Memorials"
                          className="form-check-input"
                        />
                        <label for="Memorials">Memorials</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Veterans"
                          className="form-check-input"
                        />
                        <label for="Veterans">Veterans support</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="personnel"
                          className="form-check-input"
                        />
                        <label for="personnel">Service personnel support</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Convalescence"
                          className="form-check-input"
                        />
                        <label for="Convalescence">Convalescence</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Other6"
                          className="form-check-input"
                        />
                        <label for="Other6">Other</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Harmony"
                  className="form-check-input"
                  onChange={() => setShowHarmony(!showharmony)}
                />
                <label for="Harmony" className="category-title">
                  Human Rights/Religious Harmony, Racial Harmony, Equality or
                  Diversity
                </label>
              </div>

              {/* sub category */}
              {showharmony && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Human"
                          className="form-check-input"
                        />
                        <label for="Human">Human Rights</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="reconciliation"
                          className="form-check-input"
                        />
                        <label for="reconciliation">
                          Conflict resolution/reconciliation
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Religious"
                          className="form-check-input"
                        />
                        <label for="Religious">Religious harmony</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Racial"
                          className="form-check-input"
                        />
                        <label for="Racial">Racial harmony</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Equality"
                          className="form-check-input"
                        />
                        <label for="Equality">Equality and diversity</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Civil"
                          className="form-check-input"
                        />
                        <label for="Civil">Civil rights</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Other7"
                          className="form-check-input"
                        />
                        <label for="Other7">Other</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Recreation"
                  className="form-check-input"
                  onChange={() => setShowRecreation(!showrecreation)}
                />
                <label for="Recreation" className="category-title">
                  Recreation
                </label>
              </div>

              {/* sub category */}
              {showrecreation && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Village"
                          className="form-check-input"
                        />
                        <label for="Village">Village hall,</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="centre"
                          className="form-check-input"
                        />
                        <label for="centre">community centre</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="recreation-ground"
                          className="form-check-input"
                        />
                        <label for="recreation-ground">Recreation ground</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="space"
                          className="form-check-input"
                        />
                        <label for="space">Open space</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Leisure"
                          className="form-check-input"
                        />
                        <label for="Leisure">Leisure centre</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Youth"
                          className="form-check-input"
                        />
                        <label for="Youth">Youth centre</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Other8"
                          className="form-check-input"
                        />
                        <label for="Other8">Other</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="category-list">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="charitable"
                  className="form-check-input"
                  onChange={() => setShowCharitable(!showcharitable)}
                />
                <label for="charitable" className="category-title">
                  Other charitable purposes
                </label>
              </div>

              {/* sub category */}
              {showcharitable && (
                <div className="sub-category-list">
                  <div className="row g-3">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="transport"
                          className="form-check-input"
                        />
                        <label for="transport">Community transport</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Agricultural"
                          className="form-check-input"
                        />
                        <label for="Agricultural">Agricultural show</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="respite"
                          className="form-check-input"
                        />
                        <label for="respite">Break and respite care</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Residential"
                          className="form-check-input"
                        />
                        <label for="Residential">Residential care</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="living"
                          className="form-check-input"
                        />
                        <label for="living">Supported living</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Day"
                          className="form-check-input"
                        />
                        <label for="Day">Day Care</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="social"
                          className="form-check-input"
                        />
                        <label for="social">Other social care</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="volunteering"
                          className="form-check-input"
                        />
                        <label for="volunteering">
                          Promotion of volunteering/Voluntary sector
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="effectiveness"
                          className="form-check-input"
                        />
                        <label for="effectiveness">
                          Promotion of efficiency/effectiveness of charities
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Uniformed"
                          className="form-check-input"
                        />
                        <label for="Uniformed">Uniformed youth groups</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="groups"
                          className="form-check-input"
                        />
                        <label for="groups">Non-uniformed youth groups</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="Other9"
                          className="form-check-input"
                        />
                        <label for="Other9">Other</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <h3>
            If you are interested in gaining access to updates on this research,
            please enter your contact information below. We will contact you
            concerning publication of the results:
          </h3>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email Address"
                />
              </div>
            </div>
          </div>
          <button className="next-page login-btn">Submit</button>
        </div>
      </div>
    </>
  );
};

export default QuestionFour;
