import React, { useState } from "react";
import Header from "../Layouts/Header";
import { useLocation, useNavigate } from "react-router-dom";

const Survey = () => {
  const navigate = useNavigate();
  const [agree, setAgree] = useState("");
  const [disagree, setDisagree] = useState("");
  const [loading, setLoading] = React.useState(false);
  // const [error, setError] = React.useState("");

  const { state } = useLocation();



  const stateData = (state && state?.path) || state;
  const stateDataString = JSON.stringify(stateData);

  const parts = stateDataString?.split("/survey/")[1];
  const uniqueCodes = parts?.split("/");
  const firstUniqueCode = uniqueCodes ? uniqueCodes[0] : "";
  const secondUniqueCode = uniqueCodes ? uniqueCodes[1] : "";



  const participantvUniqueCode = localStorage.getItem(
    "participantvUniqueCodeNew"
  );

  React.useEffect(() => {
    const storedAgree = localStorage.getItem("agree");
    const storedDisagree = localStorage.getItem("disagree");

    if (storedAgree) setAgree(storedAgree);
    if (storedDisagree) setDisagree(storedDisagree);
  }, []);

  const handleAgreeClick = (Agree) => {
    setAgree(Agree);
    setDisagree("");
    localStorage.setItem("agree", Agree);
    localStorage.removeItem("disagree");
  };

  const handleDisagreeClick = (Disagree) => {
    setDisagree(Disagree);
    setAgree("");
    localStorage.setItem("disagree", Disagree);
    localStorage.removeItem("agree");
  };

  const handleSubmit = () => {
    // if (!agree && !disagree) {
    //   setError("please select any one of the above.");
    // } else {
    //   setError("");
    // }
    setLoading(true);

    setTimeout(() => {
      if (!agree && !disagree) {
        navigate("/survey/thank-you");
      } else if (agree) {
        navigate("/general-info", {
          state: {
            vUniqueCode:
              state?.surveyCode || state?.vUniqueCode || secondUniqueCode,
            SurveyId: state?.surveyId,
            participantvUniqueCode: participantvUniqueCode,
          },
        });
      } else if (disagree) {
        navigate("/survey/thank-you");
      }
      setLoading(false);
    }, 1000);
  };

  return (
    <React.Fragment>
      <Header />

      <div className="survey-page">
        <div className="container">
          <h1 className="main-title text-start">
            Research Ready Nottingham and Nottinghamshire: Building a Dashboard
            to Increase Diversity of Research Engagement.
          </h1>
          <div className="survey-content">
            <h3>About the project</h3>
            <p>
              You are invited to take part in this research study. This study
              focuses on making more visible and discoverable the VCSE
              organisations that are interested in and are open to engagement
              with academic research. The ultimate objective of this project is
              the creation of a digital platform to enhance the research
              capabilities and understanding of Nottinghamshire’s communities.
            </p>
            <ul>
              <li>
                You have been provided with information about the project.
              </li>
              <li>
                You have been told who to contact if you have questions before,
                during or after your participation.
              </li>
              <li>
                You understand what participation in this project involves.
              </li>
              <li>You are 18 years of age or older.</li>
              <li>You voluntarily agree to participate.</li>
            </ul>
            <div className="form-check">
              <input
                type="radio"
                className="form-check-input"
                id="Agree"
                name="radio1"
                value="Agree"
                onChange={() => handleAgreeClick("Agree")}
                checked={agree === "Agree"}
              />
              <label for="Agree">Agree</label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                className="form-check-input"
                id="Disagree"
                name="radio1"
                value="Disagree"
                onChange={() => handleDisagreeClick("Disagree")}
                checked={disagree === "Disagree"}
              />
              <label for="Disagree">Disagree</label>
            </div>
{/* 
            {error && <div style={{ color: "red" }}>{error}</div>} */}
            {loading ? (
              <>
                {" "}
                <button className="login-btn mt-3" type="button" disabled>
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              </>
            ) : (
              <button
                className="login-btn mt-3"
                type="submit"
                onClick={handleSubmit}
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Survey;
