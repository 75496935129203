import React from "react";
import { Link, useLocation } from "react-router-dom";

const ErrorPage = () => {
  const location = useLocation();
  const { code } = location.state || {};

  return (
    <div className="highlight-msg">
      <a href="/" className="login-form-logo">
        <img src="assets/image/login-logo.png" alt="loginlogoimage" />
      </a>
      <div className="text">
        <h1 className="error-color" style={{ color: "red" }}>
          {code === 404 ? "Failed !!!" : "Error !!"}
        </h1>
        <p>
          {code === 404
            ? "Sorry, this survey does not exist any more, please check other survey from the website."
            : "Sorry, but something went wrong please try again after sometime."}
        </p>
        <div className="text-center mt-3">
          <Link to={code === 404 ? "/" : "/login"} className="login-btn">
            Continue
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
